.calendar {
    width: 100%;
    height: 100%;
    display: flex;
}

.calendarBody {
    padding: 10px 20px;
    width: calc(100% - 330px);
    height: 80vh;
    background-color: #0000;
    display: flex;
    border: 1px solid black;
    overflow: auto;
}

.calendarHoursWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.hour {
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
    border-bottom: 1px solid gainsboro;
}

.eventList {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.event {
    padding: 5px;
    margin: 3px;
    background-color: #dc47943e;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}

.event:hover {
    color: #fff;
}

.eventHeader {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.moreButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
}

.eventTime {
    font-size: 11px;
    color: #acacac9e;
}

.miniCalendar {
    margin: 10px;
    padding: 10px;
    width: 230px;
    min-height: 180px;
    max-height: 230px;
    background-color: #14192d;
    border-radius: 10px;
}

.week {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
}

.day {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
}

.calendarHeader {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
}

.calendarHeaderCell {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    cursor: default;
}

@media (max-width: 767px) {
    .calendar {
        flex-wrap: wrap-reverse;
    }

    .calendarBody {
        width: 100%;
    }
}