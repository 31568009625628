.cell {
    position: relative;
    width: 15%;
    height: 100%;
    border: 1px solid #6e7f9580;
}

.cellHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.day {
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

@media (max-width: 1199px) {
    .day {
        height: 35px;
        width: 35px;
    }
}

@media (max-width: 575px) {
    .cellHeader {
        padding: 7px;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 425px) {
    .day {
        height: 25px;
        width: 25px;
    }

    .cellHeader {
        padding: 6px;
    }
}