.toolbar {
  color: black;
  background-color: #2d3441;
  border: 1px solid #4b4d55;
}
.editor {
  padding: 0px 10px;
  background-color: #2d3441;
  border: 1px solid #4b4d55;
  border-radius: 4px;
  color: white;
}
.customEditorOption {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid black;
  background: #a5a3a3;
  color: #212121;
  margin: 0px 2px;
  padding: 5px;
  font-size: 14px;
  height: 30px;
}
.customEditorBtn {
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid black;
  background: #a5a3a3;
  color: #ffffff;
  margin: 0px 2px;
  padding: 5px;
  font-size: 14px;
  height: 30px;
}

.customEditorBtnPreview {
  height: 50px;
  width: 150px;
  background-color: #e24694;
  border-radius: 5px;
  display: grid;
  place-content: center;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  margin: 20px;
}

.expandedAreaWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  margin-left: 10px;
  position: absolute;
  width: 200px;
  min-height: 100px;
  background: white;
  border-radius: 2px;
  padding: 5px;
  z-index: 10;
  box-shadow: 3px 3px 5px #BFBDBD;
}
