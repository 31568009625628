.modal-filter__container {
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 20px;
}

.modal-filter__container .modal-filter__tab-name {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}

.leads-modal-filter .modal-filter__list {
    height: 200px;
    overflow: auto;
}

.leads-modal-filter .modal-filter__option {
    padding: 7px 5px 7px 5px;
    display: flex;
    align-items: center;
}

.leads-modal-filter .modal-filter__option-name {
    margin-left: 5px;
}