.wrapper {
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  min-width: 100px;
}
.title {
  padding: 0px 5px;
  font-size: 18px;
}
.selectorWrapper {
  margin: 0px 10px;
  margin-top: 5px;
}
