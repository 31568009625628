.wrapper {
  height: 600px;
  overflow-x: auto;
  color: #3D404F;
}

.wrapper span {
  color: #3D404F;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.header {
  background: #F2F2F2;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.companyBigLogo {
  height: 40px;
}
.avatar {
  text-align: center;
  background-color: #4E5B92;
  width: 40px;
  height: 40px;
  padding-top: 10px;
  border-radius: 25px;
}
.avatar span {
  color: #fff;
}
.securityTipTitle {
  margin: 3px;
}
.securityTipTitle span {
  color: #3D404F;
  font-size: 13px;
}
.securityTipsList {
  list-style: disc outside;
  padding: 0;
  margin-top: 11px;
}
.securityTipsList li {
  color: #181A21;
  margin-bottom: 8px;
  margin-left: 19px;
  font-size: 12px;
}
.footerContentWrapper {
  display: flex;
  justify-content: space-between;
}
.companySmallLogo {
  height: 24px;
}
.button {
  width: fit-content;
  display: block;
  background-color: #34693F;
  border-radius: 2px;
  margin: 20px;
  padding: 10px 24px 10px 24px;
  font-size: 14px;
  text-decoration: none;
}
