.wrapper {
  display: flex;
  overflow: auto;
  height: 600px;
  width: 700px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.itemWrapper {
  width: 600px;
  background: #2d3238;
  border-radius: 10px;
  padding: 20px;
}

.itemHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.itemTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.verifyType {
  font-size: 22px;
}

.approveBtn {
  border-radius: 6px;
  background: #09C575;
  font-size: 16px;
  padding: 6px 12px;
  border: none;
  margin: 0px 5px;
  color: white;
}

.declineBtn {
  border-radius: 6px;
  background: red;
  font-size: 16px;
  padding: 6px 12px;
  border: none;
  margin: 0px 5px;
  color: white;
}

.editBtn {
  border-radius: 6px;
  background: #e2bb02;
  font-size: 16px;
  padding: 6px 12px;
  border: none;
  margin: 0px 5px;
  color: white;
}

.verifyStatus {
  font-size: 16px;
}

.imageWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.imageExpanded {
  height: 320px;
}

.imageNotExpanded {
  height: 150px;
}

.imageWrapper img {
  height: inherit;
  cursor: pointer;
}

.downloadBtn {
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
}

.uploadedAtSpan {
  color: #6E7F95;
  font-weight: 500;
}

.skeletonLoader {
  display: inline-block;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #2d3238 25%, #626973 50%, #2d3238 75%);
  background-size: 200% 100%;
  animation: shimmer-dark 1.5s infinite linear;
}

@keyframes shimmer-dark {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}