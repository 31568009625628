.changePassWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    padding: 20px 20px;
    border-radius: 10px;
    background: #252a47;
}
.changePassTitle {
    margin-bottom: 5px;
    color: #ffffff;
    font-size: 24px;
}

.changePassInputContainer {
    width: 100%;
    padding-top: 0.5rem;
}

.changePassInputLabel {
    font-size: 14px;
    margin-bottom: 5px;
    color: #ffffff;
}

.changePassInputField {
    width: 100%;
    padding: 6px 12px;
    outline: none;
    box-shadow: none;
    border-radius: 0.25rem;
    border: 1px solid #374057;
    background-color: #374057;
    color: #ffffff;
    min-height: 45px;
    font-weight: 400;
    line-height: 1.5;
}

.changePassInputFieldContainer {
    position: relative;
    display: flex;
    flex-direction: row;   
}

.changePassInputBtn {
    margin: 15px 0;
    background: #2f6e9f;
    border-radius: 10px;
    color: #fff;
    padding: 8px 16px;
    border: none;
}

.changePassValidationField {
    margin: 0;
    color: red;
    font-size: 14px;
}

.changePassIcon {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}