.dt-actions__container {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.dt-actions__container .dt-actions__selected-counter {
    color: #fff;
}

.dt-actions__container .dt-actions__dropdown .dropdown-menu {
    background-color: #374057;
    width: 230px;
    min-width: unset;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 10px 15px;
    max-height: 400px;
    overflow-y: scroll;
}

.dt-actions__container .dt-actions__dropdown .dropdown-item {
    color: #fff;
    text-transform: uppercase;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
}

.dt-actions__container .dt-actions__action-row {
    display: flex;
    align-items: center;
}

.dt-actions__container .dt-actions__action-row input {
    margin-top: 0;
    max-height: 41px;
}

.dt-actions__container .dt-actions__dropdown .dropdown-item:hover {
    background-color: unset;
}

.btn-default.bg-green {
    background-color: #008000;
}
.btn-default.bg-red {
    background-color: #822828
}

@media (max-width: 991px) {
    .dt-actions__container {
        justify-content: space-between;
    }
}