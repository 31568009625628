.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.col1 {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
}
.col2 {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.balanceWrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  background: #424b55;
  border: 1px solid #424b55;
  border-radius: 10px;
  width: 330px;
  padding: 5px;
}

.balanceInputWrapper {
  padding: 5px;
}
.balanceInputTitle {
  font-size: 18px;
  padding: 0px 5px;
}
.balanceInputBlock {
  padding: 5px 10px;
}
.balanceInput {
  padding: 5px;
  width: 290px;
  background: #434b53;
  border: 1px solid #383b3e;
  border-bottom: 3px solid #383b3e;
  border-radius: 4px;
  color: white;
  outline: none;
}

.errorMessage {
  padding: 0px 15px;
  font-size: 16px;
  color: red;
}

.configureSelectorsWrapper {
  display: flex;
  max-height: 600px;
  overflow-x: hidden;
}

.buttonsWrapper {
  display: flex;
}

.errorMessageWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 10px 0px;
}
