.modalTime {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.modalTimeInput {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: white;
    background-color: #1d2038;
    border: none;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.eventModalNameWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nameInput {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: white;
    background-color: #1d2038;
    border: none;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.modalDuration {
    margin-top: 15px;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.modalDurationSelector {
    width: 100%;
    background-color: #1d2038;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.modalDescriptionWrapper {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.eventModalDescription {
    padding: 15px;
    color: #fff;
    background-color: #56587c7c;
    border-radius: 10px;
}

.eventWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 14px;
    width: 150px;
    padding: 15px;
    background-color: #252b48;
    border-radius: 10px;
    z-index: 10;
    gap: 5px;
}

.commentsHistoryWrapper {
    margin-top: 20px;
    /* width: 300px; */
    width: 100%;
    display: flex;
    height: 100px;
    overflow: auto;
}

.commentsHistory {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.commentsHistoryCell {
    width: 100%;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid whitesmoke;
}

.commentsHistoryCellBtns {
    display: flex;
    margin-right: 10px;
    gap: 4px;
}

.btnsCell {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 20px;
    height: 20px;
}

.commentsHistoryCellRowInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.commentsHistoryCellInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 8px;
    padding-bottom: 8px;
}

.commentsHistoryCellInfoText {
    color: whitesmoke;
    margin-bottom: 0;
}

.commentsHistoryCellInfoTextBold {
    color: whitesmoke;
    margin-bottom: 0;
    font-weight: bold;
}

.commentInput {
    width: 100%;
    background-color: #1d2038;
    border-radius: 10px;
    color: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border: none;
    outline: none;
}