.dt-actions__dropdown .dropdown-menu {
    background-color: #374057;
    width: 200px;
    min-width: unset;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 10px 15px;
}

.dt-actions__dropdown .dropdown-item {
    color: #fff;
    text-transform: uppercase;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
}

.dt-actions__dropdown .dropdown-item:hover {
    background-color: unset;
}

.dt-actions__dropdown.secondary button {
    width: 100%;
    max-width: 100%;
}

.dt-filters__btn-row {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.dt-filters__btn-row button:last-child {
    margin-top: 5px;
}

.dt-actions__dropdown .dt-filters__filters-selector {
    overflow: hidden;
    text-overflow: ellipsis;
}