.eventList {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.event {
    padding: 5px;
    margin: 3px;
    background-color: #dc47943e;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}

.event:hover {
    color: #fff;
}

.eventHeader {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.moreButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
}

.eventTime {
    font-size: 11px;
    color: #acacac9e;
}

@media (max-width: 1024px) {
    .eventHeader span {
        display: none;
    }
}

@media (max-width: 575px) {
    .eventTime {
        font-size: 7px;
    }

    .eventHeader svg {
        font-size: 10px;
    }
}