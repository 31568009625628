.calendarHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #252a4750;
}

.calendarHeaderCell {
    width: 15%;
    height: 48px;
    padding: 12px;
    border: 1px solid rgba(110,127,149,.5019607843137255);
    display: flex;
    justify-content: flex-end;
    color: #acacac9e;
}

@media (max-width: 575px) {
    .calendarHeaderCell {
        padding: 7px;
        justify-content: center;
        align-items: center;
    }
}