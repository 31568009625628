.columns-dropdown .dropdown-menu {
    background-color: #374057;
    width: 200px;
    min-width: unset;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  }
  
  .columns-dropdown .dropdown-menu .btn-default {
    width: 90px;
  }
  
  .columns-dropdown .dropdown-menu .columns-list {
    padding: 0 15px;
  }
  
  .columns-dropdown .dropdown-menu .columns-list .columns-option {
    cursor: pointer;
  }
  
  .columns-dropdown .dropdown-menu .columns-list .columns-option .columns-option__name {
    margin-left: 5px; 
    color: #fff; 
    font-size: 14px; 
    text-transform: uppercase;
    padding: 5px 0;
  }

  .columns-dropdown  .column-btn {
    display: flex;
  }

  .columns-dropdown  .column-btn svg {
    margin-right: 5px;
  }