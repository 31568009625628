.eventsWrapper {
    height: 300px;
}

.eventsHeaderWrapper {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.eventModalNameWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nameInput {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: white;
    background-color: #1d2038;
    border: none;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.modalTime {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.modalTimeInput {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: white;
    background-color: #1d2038;
    border: none;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.modalDuration {
    margin-top: 15px;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.modalDurationSelector {
    width: 100%;
    background-color: #1d2038;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.modalDescriptionWrapper {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.eventModalDescription {
    padding: 15px;
    color: #fff;
    background-color: #1d2038;
    border-radius: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.eventsBodyWrapper {
    height: 200px;
    padding: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.eventWrapper {
    padding: 10px;
    background-color: #dc47943e;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    position: relative;
}


.eventTime {
    font-size: 11px;
    color: #acacac9e;
}

.moreButton {
    position: absolute;
    padding: 10px;
    right: 15px;
    cursor: pointer;
}

.eventClosedButton {
    padding: 5px 15px;
    position: absolute;
    right: 50px;
    border-radius: 15px;
    background-color: #da3633;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.eventModalWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 20px;
    right: 45px;
    width: 150px;
    padding: 15px;
    background-color: #252b48;
    border-radius: 10px;
    z-index: 10;
    gap: 5px;
}