.t-settings__dropdown .dropdown-menu {
    background-color: #374057;
    width: 200px;
    min-width: unset;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 10px 15px;
    max-height: 230px;
    overflow-y: scroll;
}

.t-settings__dropdown .dropdown-item {
    color: #fff;
    text-transform: uppercase;
    padding: 5px 0;
    font-size: 14px;
    cursor: pointer;
}

.t-settings__dropdown .dropdown-item:hover {
    background-color: unset;
}

.t-settings_dropdown-btn {
    width: 100%;
    color: white;
    border: 1px solid #2d3440;
    background-color: #2d3440;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
}

.t-settings_dropdown-btn:hover {
    color: white
}

.t-settings_input-group .btn {
    border: 1px solid #2d3440;
    background-color: #2d3440;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 0!important;
}

.t-settings_input-group .btn:after {
    content: unset;
}

.t-settings_input-group .dropdown-menu {
    background-color: #374057;
    width: 100px;
    min-width: unset;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    color: white;
}

.t-settings_input-group .dropdown-menu .dropdown-item {
    color: white;
}

.t-settings_input-group .dropdown-menu .dropdown-item:hover {
    background-color: unset;
}

.mt-15 {
    margin-top: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.label-with-info {
    display: flex;
    align-items: center;
}

.label-with-info span {
    margin-right: 5px;
}