.cfdAccount_idWrapper {
    display: flex;
    flex-direction: column;
    height: 120px;
}

.cfdAccount_idWrapper_text {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.cfdAccount_input {
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    background-color: #374057;
    color: #ffffff;
    border-bottom: 1px solid #000000;
    border-radius: 5px;
    border: none;
    outline: none;
}

.cfdAccount_errorInputWrapper {
    height: 20px;
}

.cfdAccount_errorInput {
    text-transform: none;
    font-size: 11px;
    color: red;
    margin-bottom: 0;
    margin-top: 10px
}

.cfdSccounts-row_ClientWrapper {
    width: 100%;
    padding-left: 30px;
    display: lex;
    gap: 3px;
    position: relative;
    justify-content: flex-start;
}

.cfdSccounts-row_Id:hover {
    cursor: pointer;
}

.cfdSccounts-row_fullName {
    margin-left: 5px;   
}

.cfdSccounts-row_fullName:hover {
    color: rgb(61, 61, 247);
}

