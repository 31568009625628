.wrapper {
  max-height: 600px;
  overflow-x: hidden;
}
.button {
  width: fit-content;
  display: block;
  background-color: #34693F;
  border-radius: 2px;
  margin: 20px;
  padding: 10px 24px 10px 24px;
  font-size: 14px;
  text-decoration: none;
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 10px;
}
