.wrapper {
  height: 300px;
  padding: 10px;
  background-color: #dc47943e;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
}

.headerWrapper {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.bodyWrapper {
  height: 200px;
  padding: 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.statusHistoryRow {
  background: #313865;
  padding: 2px 8px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
