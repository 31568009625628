.wrapper {
  height: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
}

.headerWrapper {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.bodyWrapper {
  height: 200px;
  padding: 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.callHistoryWrapper {
  background: #313865;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
}

.eventTime {
  font-size: 11px;
  color: #acacac9e;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #3f477b;
  }

  100% {
    background-color: hsl(204, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 11px;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-textBody {
  height: 17px;
}

.skeleton-footer {
  height: 11px;
  width: 30%;
}