.calendarSubHeader {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.subHeaderButtons {
    display: flex;
    gap: 5px;
    user-select: none;
}

.subHeaderButtons>.viewButton {
    padding: 5px;
    background-color: #6e7f95;
    color: #1d2038;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
}

.subHeaderButtons>.viewButton:hover {
    background-color: #515d6c;
}

.subHeaderButtons>.arrowButtons {
    padding: 5px;
    background-color: #6e7f95;
    border-radius: 5px;
    color: #1d2038;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.subHeaderButtons>.todayButton {
    padding: 0 10px;
    font-size: 16px;
    background-color: #6e7f95;
    border-radius: 5px;
    color: #1d2038;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.subHeaderButtons>.todayButton:hover,
.subHeaderButtons>.arrowButtons:hover {
    background-color: #515d6c;
    cursor: pointer;
}

@media (max-width: 575px) {
    .calendarSubHeader > div {
        width: 100%;
        margin: 10px 0;
    }
}