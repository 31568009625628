.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.col1 {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}
.col2 {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}
.item {
  display: flex;
  flex-direction: column;
  padding: 2px;
}
.emailInput, .passwordInput, .descriptionInput {
  border: 1px solid #2d3440;
  background-color: #2d3440;
  color: white;
  padding: 5px 10px;
  width: 250px;
}
.emailInput:focus, .passwordInput:focus, .descriptionInput:focus {
  background-color: #2d3440;
  color: white;
}
.agentSelector, .providerSelector {
  border: 1px solid #2d3440;
  background-color: #2d3440;
  color: white;
  padding: 5px 10px;
  width: 250px;
}
.btnWrapper {
  display: flex;
  justify-content: flex-end;
}
