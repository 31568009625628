.loadingDotsWrapper {
    width: 100%;
    display: flex;
    gap: 10px;
}

.loadingDots {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 2px;
    background-color: #fff;
    color: #fff;
    animation: loading_dots 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.loadingDots::before,
.loadingDots::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.loadingDots::before {
    left: -7px;
    width: 5px;
    height: 5px;
    border-radius: 2px;
    background-color: #fff;
    color: #fff;
    animation: loading_dots 1s infinite alternate;
    animation-delay: 0s;
}

.loadingDots::after {
    left: 7px;
    width: 5px;
    height: 5px;
    border-radius: 2px;
    background-color: #fff;
    color: #fff;
    animation: loading_dots 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes loading_dots {
    0% {
        background-color: #fff;
    }

    50%,
    100% {
        background-color: rgba(197, 197, 197, 0.2);
    }
}